import React from "react";
import { Formik, Form } from "formik";
import { MultiSelect } from "../formValidation/MultiSelect";
import { useState } from "react";
import "./Style.css";
import { Toggle } from "../formValidation/Toggle";
import TabHistoryModel from "./EngageHistory/TabHistoryModel";
import PolicyInfoModel from "./PolicyInfo/PolicyInfoModel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import DaynmicApicall, { scoreUpload } from "../../utils/function";
import { ManageEventLog } from "../../utils/function";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import {
  removeCurrentCall,
  setdialerInfo,
  setSaveandExit,
} from "../../redux/Dialer";
import { removeCrmWebphone, setCrmWebphone } from "../../redux/dialerStates";
import { setGlobalLanguage } from "../../redux/Campaign";
import api from "../../utils/api";
import { TextField } from "../formValidation/TextField";
import {
  escalationCall,
  lbRedialCall,
} from "../DespositionComponents/comonentFunctions";
import { error } from "jquery";
import { removeCallSession } from "../../redux/CurrentCallSessionDetails";
import socket from "../../utils/socket";

const CardDetails = (props, { label }) => {
  const atmAssigned =
    props?.data?.custinfo?.case_status === 1 &&
    props?.data?.custinfo?.cust_allocto !== null;

  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, varifier, usergroup, dlrAgentId, username } =
    userInfo.data[0];
  let {
    data: { campinfo },
  } = props;
  let { crmsaveandexit, customerInfo } = useSelector((state) => state?.dialer);
  let { language } = useSelector((state) => state?.campaign);
  let { crmwebphone } = useSelector((state) => state?.webphoneStatus);
  const { callScore } = useSelector((state) => state.callsession);
  let DialerCrmData = localStorage.getItem("currentcall")
    ? JSON.parse(JSON.parse(localStorage.currentcall).crm_data)
    : "";

  const dispatch = useDispatch();
  const [assume, setAssume] = useState(atmAssigned);
  const [showdata, setShowdata] = useState(false);
  const [days, setDays] = useState([]);
  const [pageData, setpageData] = useState("");
  const [logdata, setLogdata] = useState("");
  const [loading, setLoading] = useState(false);
  const [dispositionMark, setDispositionMark] = useState(0);
  const [isMounted, setIsMounted] = useState(true);
  const [radioSlected, setRadioSlected] = useState(false);
  const [score, setScore] = useState("");

  let actionlog = {
    Rowed: "NEW",
    custid: props?.data?.custinfo?.cust_id,
    campid: props?.data?.campinfo?.campid,
    eventname: ``,
    actionname: "",
    createdby: userid,
  };

  useEffect(() => {
    setScore(callScore);
  }, [callScore]);

  async function getDays() {
    await DaynmicApicall(
      `prm/getParamdata/ALL/DAYS/${campinfo?.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        let bData = [];
        bData = await res?.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setDays(bData);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }
  const handleChange = async (value) => {
    actionlog.eventname = `OnChange`;
    actionlog.actionname = `Select Date from ${
      value === true ? "calender" : "dropdown"
    }`;
    ManageEventLog(actionlog);
    setShowdata(value);
  };
  const initial = {
    consent: "",
    scripttype: "",
    date: "",
  };
  const handleAssume = (value) => {
    actionlog.eventname = `OnChange`;
    actionlog.actionname = `Assign to me`;
    ManageEventLog(actionlog);
    setAssume(value);
  };
  async function caseblock() {
    let parameter = {
      agentid: userid,
      cust_cuid: props.data.custinfo.cuid,
      campid: campinfo.campid,
      event_name: "CASE-BLOCK",
      entry_by_agent: userid,
      session_id: crmsaveandexit?.dialer_session_id,
      srv_code: "",
      srv_message: "",
      action_name: "UPDATE",
      createdby: userid,
      active: "Y",
      keypointer: campinfo.keypointer,
    };
    actionlog.eventname = `OnClick`;
    actionlog.actionname = `click on caseblock`;
    actionlog.actioncat = "CASE-BLOCK";

    const Info = await DaynmicApicall(
      `mcrmdlr/caseblock`,
      parameter,
      "post",
      userInfo.data[0].UserToken
    );
    toast.success(Info.message);
  }

  async function assignToMe() {
    let date = new Date().toISOString("en-US", {
      timeZone: "Asia/Kolkata",
    });
    let newdate = date.split("T")[0];
    let parameter = {
      onclick: assume === true ? "No" : "yes",
      cuid: `${campinfo.campid}${props.data.custinfo.cust_id}`,
      dialer_sessionid: crmsaveandexit.dialer_session_id,
      agentid: userid,
      custid: props.data.custinfo.cust_id,
      respcode: assume === true ? "ATM-REVOKE" : crmsaveandexit.resp_code,
      subrespcode: assume === true ? "NA" : crmsaveandexit.sub_resp_code,
      campid: campinfo.campid,
      createdby: userid,
      action_name: "UPDATE",
      revert_date: assume === true ? newdate : crmsaveandexit.revertto_date,
      keypointer: campinfo.keypointer,
    };
    const Info = api.post("mcrmdlr/manageatmcall", parameter);
    Info.then(function (result) {
      if (result?.data?.status === false) {
        setAssume(atmAssigned);
      }
    }).catch(function (error) {
      console.log("error", error);
      setAssume(false);
    });
    toast
      .promise(Info, {
        success: (Info) => {
          return Info.data.message;
        },
        error: (err) => {
          return (
            err?.response?.data?.errors ??
            err?.response?.data?.message ??
            err?.message ??
            "OOPs something went wrong."
          );
        },
      })
      .then((data) => {});
  }
  async function splitdatetime(value) {
    let d1 = value.split("T")[0].split("-");
    let date = d1[2] + "-" + d1[1] + "-" + d1[0];
    let t1 = value.split("T")[1].split(":");
    let time = parseInt(t1[0] + t1[1]);
    let timeanddate = {
      revertto_date: date,
      revertto_time: time ? time : "0000",
    };
    Object.entries(timeanddate).map((data) => {
      let aa = `{"${data[0]}": "${data[1]}"}`;
      dispatch(setSaveandExit(JSON.parse(aa)));
    });
  }

  async function createLead() {
    let payload = {
      campid: crmsaveandexit.campid,
      custid: crmsaveandexit.cust_id,
      callid: crmsaveandexit.callid,
      contactdate: new Date().toISOString(),
      contacttime: `${new Date().getHours()} ${String(20).padStart(2, "0")}`,
      contactby: userid,
      respcode: crmsaveandexit.resp_code,
      subrespcode: crmsaveandexit.sub_resp_code,
      dialerconnectedph: crmsaveandexit.dialer_connected_ph,
      reqamount: "", // get data from disposition based lead
      agentremarks: "",
      paymentoption: "", // get data from disposition based lead
      supervisorid: varifier,
      supervisorresponse: "",
      supervisorremarks: "",
      callsessionid: crmsaveandexit.dialer_session_id,
      leadid: props.data.custinfo.leadid,
      flagstatus: crmsaveandexit.flagstatus,
      active: "Y",
      createdby: userid,
      action_name: "INSERT",
      keypointer: campinfo.keypointer,
    };

    let Info = await DaynmicApicall(
      `mcrmdlr/managelead`,
      payload,
      "post",
      UserToken
    )
      .then((data) => {})
      .catch((error) => {
        console.log("Error from manageLead", error);
      });
  }

  async function getLeadWorkflowDisp() {
    try {
      const info = await DaynmicApicall(
        `prm/getParamdata/${campinfo?.campid}/VARIFICATION-WORKFLOW/NA`,
        "",
        "get",
        UserToken
      );
      return info.find((data) => data.paravalue === crmsaveandexit.resp_code);
    } catch (error) {
      console.log("Error from getLeadWorkflowDisp api", error);
    }
  }

  async function finalCallSave() {
    try {
      setLoading(true);
      // Check Call Disconnect
      const getcalldisconnected = await DaynmicApicall(
        `dialer/getcallsiconnectevent/${DialerCrmData.dialeruuid}`,
        "",
        "get",
        UserToken
      );

      if (!isMounted) return; // Check if still mounted

      if (getcalldisconnected.CNT === 0) {
        toast("You can save & exit after call disconnect", {
          icon: "😎",
          style: {
            borderRadius: "10px",
            background: "#45abdb",
            color: "#fff",
          },
        });
        setLoading(false);
        return;
      }

      if (logdata?.length > 1 && radioSlected === false) {
        toast.error("Please select radio input before saving the call");
        setLoading(false);
        return;
      }

      // Prepare payload for call save
      const payload = {
        ...crmsaveandexit,
        script_language: language || "ENGLISH",
      };

      // Save call information
      const Info = await DaynmicApicall(
        `mcrmdlr/managecallsave`,
        payload,
        "post",
        UserToken
      );

      if (!isMounted) return; // Check if still mounted

      if (Info.status) {
        actionlog.eventname = `OnClick`;
        actionlog.actionname = `final call save`;
        ManageEventLog(actionlog);

        // Fetch lead workflow disposition
        const getLead = await getLeadWorkflowDisp();
        if (getLead) {
          await createLead();
        }
        if (crmsaveandexit?.resp_code === "LB") {
          await lbRedialCall(
            {
              agentid: props?.getLBagent?.split("~")[1],
              custid: `${crmsaveandexit?.campid}${crmsaveandexit?.cust_id}`,
              phone: [crmsaveandexit?.dialer_connected_ph],
              campid: crmsaveandexit?.campid,
            },
            userInfo.data[0].UserToken
          );
        }
        if (crmsaveandexit?.resp_code === "ESCALATION") {
          let dd = new Date();
          let escalationBody = {
            custcuid: `${crmsaveandexit?.campid}${crmsaveandexit?.cust_id}`,
            customername: customerInfo?.customer_name,
            sessionid: crmsaveandexit?.dialer_session_id,
            agentid: userid,
            esclation_to: props?.getTLId?.split("~")[0],
            campid: crmsaveandexit?.campid,
            crmuuid: DialerCrmData.dialeruuid,
            action_name: "INSERT",
            phone: [crmsaveandexit?.dialer_connected_ph],
            tlextension: props?.getTLId?.split("~")[1],
          };

          await escalationCall(escalationBody, userInfo.data[0].UserToken)
            .then((res) => {
              if (isMounted) {
                socket.emit("pushnotify", {
                  toid: props?.getTLId?.split("~")[1],
                  fromid: `${username}-${userid}`,
                  remarks: "You have received notification",
                });
              }
            })
            .catch((error) => {
              console.log("ERROR in escalation", error);
            });
        }
        //For Auto quality final score save
        const apiBody = {
          cuid: props?.data?.custinfo?.cuid,
          sessionid: crmsaveandexit?.dialer_session_id,
          crmuuid: DialerCrmData.dialeruuid,
          campid: props?.data?.campinfo?.campid,
          agentid: userInfo.data[0].userid,
          final_score: score,
          keypointer: props?.data?.campinfo?.keypointer,
        };

        await scoreUpload(apiBody, userInfo.data[0].UserToken)
          .then(async (data) => {})
          .catch((err) => {
            console.log("ERROR", err);
          });

        // Hang up the call
        const hangupdata = {
          crmuuid: DialerCrmData.dialeruuid,
          external_hangup: "1",
          external_status: `${userInfo.data[0].userid}${crmsaveandexit.resp_code}CallFromAgentPage`,
          agentid: userInfo.data[0].AgentDidNo,
        };

        await DaynmicApicall(
          `dialer/dialerhangup`,
          hangupdata,
          "post",
          UserToken
        );

        if (crmwebphone.confStatus) {
          await DaynmicApicall(
            `ami/endCallConference`,
            {
              agent: dlrAgentId,
              crmuuid: DialerCrmData.dialeruuid,
            },
            "post",
            UserToken
          );
        }

        // Reset call and UI state
        props.setDispalyTimer(false);
        dispatch(removeCurrentCall());
        dispatch(removeCallSession());
        dispatch(
          setCrmWebphone({
            confStatus: "",
          })
        );
        localStorage.removeItem("currentcall");
        localStorage.removeItem("callLogs");
        localStorage.removeItem("currentListening");
        dispatch(setGlobalLanguage({ data: "" }));
        dispatch(removeCrmWebphone());
        dispatch(setdialerInfo({ dialerinfo: "" }));

        props.aftersave("");
      }
      if (isMounted) {
        toast.success(Info.message);
      }
    } catch (error) {
      console.error("Error in finalCallSave:", error);
      if (isMounted) {
        props.setDispalyTimer(false);
        dispatch(removeCurrentCall());
        dispatch(removeCallSession());
        dispatch(
          setCrmWebphone({
            confStatus: "",
          })
        );
        localStorage.removeItem("currentcall");
        localStorage.removeItem("callLogs");
        localStorage.removeItem("currentListening");
        dispatch(setGlobalLanguage({ data: "" }));
        dispatch(setCrmWebphone({ dialerHangupCuid: "" }));
        dispatch(setCrmWebphone({ dialerHangupStatus: true }));
        dispatch(setCrmWebphone({ callStartTime: "" }));
        dispatch(setCrmWebphone({ callEndTime: "" }));
        dispatch(setdialerInfo({ dialerinfo: "" }));
        props.aftersave("");
        toast.error(error.message);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  }

  async function pageInfo(keypointer) {
    await DaynmicApicall(
      `appuser/getcomponetbyid/${props?.data?.campinfo?.keypointer}/39`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        setpageData(res[0]?.DATA);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }
  async function getActionLog() {
    await DaynmicApicall(
      `err/getactionlog/${crmsaveandexit.dialer_session_id}/${campinfo?.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        let actions = res.filter((e) => e.action_cate !== "NA").length;
        setDispositionMark(dispositionMark + actions);
        setLogdata(res);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  useEffect(() => {
    (async () => {
      if (campinfo) {
        await getDays();
        await pageInfo();
      }
    })();
  }, []);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <>
      {pageData && (
        <div className="card mt-2 ml-1 ">
          <Formik initialValues={initial}>
            {(formik) => (
              <div>
                <Form>
                  <div className="row">
                    {showdata === false ? (
                      <MultiSelect
                        className="mb-0"
                        placeholder={pageData?.mult?.placeholder}
                        title={pageData?.mult?.title}
                        data-toggle="tooltip"
                        type={pageData?.mult?.type}
                        name={pageData?.mult?.name}
                        isMulti={false}
                        options={days}
                        onChange={(values) => {
                          let newdate = new Date(
                            Date.now() +
                              1000 * 60 * 60 * 24 * values.value +
                              1000 * 60 * 60 * 5.5
                          ).toISOString("en-US", {
                            timeZone: "Asia/Kolkata",
                          });
                          splitdatetime(newdate);
                        }}
                      />
                    ) : (
                      <TextField
                        placeholder={pageData.textfield.placeholder}
                        title={pageData.textfield.title}
                        type="datetime-local"
                        name={pageData.textfield.name}
                        onChange={(e) => {
                          formik.setFieldValue("date", e.target.value);

                          splitdatetime(e.target.value);
                        }}
                      />
                    )}
                    <div className="col-sm-12 col-md-2 col-lg-2 mt-4 text-bold ">
                      <Toggle
                        label={showdata === true ? "Day/Date" : "Calendar"}
                        name={pageData?.toggle1?.name}
                        title={pageData?.toggle1?.title}
                        data-toggle="tooltip"
                        key={pageData?.toggle1?.key}
                        type={pageData?.toggle1?.type}
                        defaultChecked={formik?.values?.consent}
                        onChange={(e) => {
                          formik.setFieldValue("consent", e.target.checked);
                          handleChange(e.target.checked);
                        }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mt-4 d-flex">
                      <div className="col-sm-12 col-md-5 mt-2 pl-5">
                        <span className="mr-2 display-6 mt-2">
                          {pageData.text}
                        </span>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <Toggle
                          label={assume === false ? "No" : "Yes"}
                          name={pageData?.toggle2?.name}
                          title={pageData?.toggle2?.title}
                          data-toggle="tooltip"
                          key={pageData?.toggle2?.key}
                          type={pageData?.toggle2?.type}
                          defaultChecked={assume}
                          onChange={(e) => {
                            assignToMe();
                            formik.setFieldValue(
                              "assignMeBtn",
                              e.target.checked
                            );
                            handleAssume(e.target.checked);
                          }}
                        />
                      </div>
                      {/* Case Block */}
                      <div className="col-sm-12 col-md-5 ml-2 ml-5">
                        <button
                          className="btn btn-danger"
                          type={pageData?.caseblockbutton?.type}
                          title={pageData?.caseblockbutton?.title}
                          data-toggle="tooltip"
                          data-bs-toggle="modal"
                          data-bs-target="#caseblock"
                        >
                          Case Block
                        </button>
                        <div
                          className="modal fade"
                          id="caseblock"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="caseblockTitle"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div
                              className="modal-content"
                              style={{ width: "500px" }}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLongTitle"
                                >
                                  Conformation
                                </h5>
                              </div>
                              <div className="modal-body">
                                Do you make sure that Id is to be blocked
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn-sm btn-primary ml-6 m-2"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn-sm btn-primary ml-6 m-2 "
                                  type={pageData?.savebutton?.type}
                                  title={pageData?.savebutton?.title}
                                  data-toggle="tooltip"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={async (e) => {
                                    caseblock();
                                  }}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <button
                        type={pageData?.engagementbutton?.type}
                        className="btn btn-primary-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#modalHistory"
                        title={pageData?.engagementbutton?.title}
                        data-toggle="tooltip"
                        style={{ fontSize: "15px" }}
                      >
                        {pageData?.engagementbutton?.label}
                      </button>
                      <div
                        className="modal fade"
                        id="modalHistory"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="modalTitleNotify"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered ml-1"
                          role="document"
                        >
                          <div
                            className="modal-content "
                            style={{ marginTop: "250px" }}
                          >
                            <div className="modal-header">
                              <p className="modal-title" id="modalTitleNotify">
                                {pageData?.engagementbutton?.header}
                              </p>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <TabHistoryModel userInfo={props} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <button
                        type={pageData?.policybutton?.type}
                        className="btn btn-primary-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#modalPolicyInfo"
                        title={pageData?.policybutton?.title}
                        data-toggle="tooltip"
                        style={{ fontSize: "15px" }}
                      >
                        {pageData.policybutton.header}
                      </button>
                      <div
                        className="modal fade"
                        id="modalPolicyInfo"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="modalTitleNotify"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered ml-1"
                          role="document"
                        >
                          <div
                            className="modal-content "
                            style={{ marginTop: "250px" }}
                          >
                            <div className="modal-header">
                              <p className="modal-title" id="modalTitleNotify">
                                {pageData?.policybutton?.header}
                              </p>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <PolicyInfoModel userData={props} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="language3"
                      className=" col-sm-12 col-md-12 col-lg col-xl"
                    >
                      <div className=" justify-content-end ml-6">
                        <div className="">
                          {loading ? (
                            <button
                              className="btn-sm btn-primary ml-6 m-2"
                              disabled
                            >
                              <span className="pr-2">
                                <strong>Saving...</strong>
                              </span>
                              <CircularProgress
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  color: "white",
                                }}
                              />
                            </button>
                          ) : (
                            <button
                              className="btn-sm btn-primary ml-3 m-2 "
                              type={pageData?.savebutton?.type}
                              title={pageData?.savebutton?.title}
                              data-bs-toggle="modal"
                              data-bs-target="#finalCallSave"
                              data-toggle="tooltip"
                              onClick={async (e) => {
                                getActionLog();
                              }}
                              style={{ fontSize: "15px" }}
                            >
                              Confirm & Save
                            </button>
                          )}
                          <div
                            className="modal fade"
                            id="finalCallSave"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="finalCallSaveTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="finalCallSaveTitle"
                                  >
                                    Call Summary
                                  </h5>
                                </div>
                                <div className="modal-body">
                                  <div
                                    style={{
                                      padding: ".3em .5em .5em 1em",
                                      marginRight: "5px",
                                      letterSpacing: "1.01px",
                                      float: "none",
                                      display: "inline-block",
                                      marginBottom: "3px",
                                      fontSize: "12px",
                                      color: "#4a4a4a",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <dl
                                      className="row p-2 overflow-scroll "
                                      style={{ height: "250px" }}
                                    >
                                      {crmsaveandexit &&
                                        Object.entries(crmsaveandexit)
                                          .filter(
                                            ([key]) =>
                                              ![
                                                "DIALER CONNECTED PH",
                                                "CONTACT NO",
                                                "ALT PHONE",
                                                "EMAIL",
                                              ].includes(
                                                key
                                                  .toUpperCase()
                                                  .replaceAll("_", " ")
                                              )
                                          )
                                          .map((data, index) => (
                                            <React.Fragment key={index}>
                                              <dt
                                                className="col-3 text-info"
                                                key={index}
                                              >
                                                {data[0]
                                                  ?.replaceAll("_", " ")
                                                  ?.toUpperCase()}
                                              </dt>
                                              <dd className="col-3">
                                                {data[1]}
                                              </dd>
                                            </React.Fragment>
                                          ))}
                                    </dl>
                                  </div>

                                  {/* </Row> */}
                                  {logdata?.length > 0 &&
                                    logdata?.map((data, index) => (
                                      <div
                                        className="row align-content-end"
                                        key={index}
                                      >
                                        <div
                                          className=" col-8"
                                          style={{
                                            padding: ".3em .5em .5em 1em",
                                            marginRight: "5px",
                                            letterSpacing: "1.01px",
                                            float: "none",
                                            display: "inline-block",
                                            marginBottom: "3px",
                                            fontSize: "12px",
                                            color: "#4a4a4a",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div className="row ">
                                            <span className="justify-content-start col-10 font-weight-bold ">
                                              <span className=" text-info">
                                                Disposition
                                              </span>{" "}
                                              - {data.resp_code}
                                              <span className="text-info ml-2">
                                                Sub-Disposition
                                              </span>{" "}
                                              -{data.sub_resp_code}{" "}
                                            </span>
                                            <div className="justify-content-end col-2">
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    border: "1px solid",
                                                  }}
                                                  type="radio"
                                                  name="flexRadioDefault"
                                                  id="flexRadioDefault1"
                                                  onChange={(e) => {
                                                    if (logdata?.length > 1) {
                                                      setRadioSlected(true);
                                                    }
                                                    dispatch(
                                                      setSaveandExit({
                                                        resp_code:
                                                          data.resp_code,
                                                      })
                                                    );
                                                    dispatch(
                                                      setSaveandExit({
                                                        sub_resp_code:
                                                          data.sub_resp_code,
                                                      })
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {/* </Row> */}
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn-sm btn-primary ml-6 m-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    Cancel
                                  </button>
                                  {dispositionMark > 0 && (
                                    <>
                                      {loading ? (
                                        <button
                                          className="btn-sm btn-primary ml-6 m-2"
                                          disabled
                                        >
                                          <CircularProgress
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                              color: "white",
                                            }}
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          id="saveid"
                                          className="btn-sm btn-primary ml-6 m-2 "
                                          type={pageData?.savebutton?.type}
                                          title={pageData?.savebutton?.title}
                                          data-toggle="tooltip"
                                          data-bs-dismiss="modal"
                                          onClick={async (e) => {
                                            setLoading(true);
                                            finalCallSave();
                                          }}
                                        >
                                          {pageData?.savebutton?.label}
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default CardDetails;
